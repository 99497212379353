.rounded {
  padding: 0.5em;
  border-radius: 50%;
  background-color: $blue-dark;
  border: solid $blue-dark;
  text-align: center;
  &::before {
    color: $white;
  }
}

.invert-rounded {
  background-color: $white;
  padding: 0.2em;
  &::before {
    color: $blue-dark;
  }
}

// TODO: Need to make the rounded classes as props for a custom icon component
.custom-rounded {
  color: $white;
  background-color: $turquoise;
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 42px;
  border-radius: 50%;
}
