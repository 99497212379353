.sv_main {
  .sv_q_matrix_dynamic {
    .sv_matrix_row {
      &:last-child {
        .sv_matrix_cell {
          padding-bottom: 0;
        }
      }

      .sv_matrix_cell {
        &:nth-child(6),
        &:nth-child(7),
        &[data-responsive-title="BankID"] {
          min-width: 10rem;
          vertical-align: middle;

          .sv_q_radiogroup_label {
            line-height: 1;
            margin-bottom: 0.625rem;

            .sv_q_radiogroup_control_item {
              margin-top: 0.25rem;
            }
          }
        }
      }
    }

    .sv_matrix_cell {
      padding-right: var(--md-spacing);
      padding-bottom: var(--md-spacing);
      min-width: 12.5rem;

      &[data-responsive-title="BankID"] {
        min-width: 5rem;
      }

      &[data-responsive-title="Type"] > div > fieldset {
        display: flex;
        gap: 20px;

        input[type="radio"]:disabled:checked {
          filter: unset;
        }
      }

      &:last-child {
        padding: 0;
      }

      .sv-action-bar {
        margin: 0;
      }

      .sv_matrix_dynamic_button {
        background: var(--transparent) !important;
        color: var(--text) !important;
        padding: 0;

        .sv-string-viewer {
          display: none;
        }

        &:hover {
          color: var(--blue);
        }
      }
    }
  }
  div[data-key="t_ubo0"],
  div[data-key="k_ubo0"] {
    .sv_q_matrix_dynamic {
      thead {
        display: none;
      }

      tbody {
        .sv_matrix_row {
          border-bottom: 1px solid var(--grey-thin);
          margin-bottom: var(--md-spacing);

          &:last-child {
            .sv_matrix_cell {
              padding-bottom: var(--md-spacing);
            }
          }
        }
      }

      .sv_matrix_row {
        display: flex;
        flex-wrap: wrap;

        .sv_matrix_cell {
          display: block;
          width: 25%;

          &:before {
            padding-top: calc(2 * var(--base-unit, 8px));
            padding-bottom: calc(1 * var(--base-unit, 8px));
            content: attr(data-responsive-title);
            font-weight: var(--semibold-weight);
            display: block;
            text-align: left;
            color: var(--secondary);
            font-size: var(--fs-milli);
          }

          select {
            max-width: 12.5rem;
          }

          &.sv_matrix_cell_actions {
            div {
              height: 100%;

              .sv_matrix_dynamic_button {
                margin-bottom: var(--xxl-spacing);
              }
            }
          }
        }
      }
    }
  }

  .sv_q_footer {
    margin-top: var(--md-spacing);

    .sv_matrix_dynamic_button {
      background: var(--transparent) !important;
      color: var(--text) !important;
      padding: 0;

      .sv-string-viewer {
        display: none;
      }

      &:hover {
        background: var(--blue);
      }
    }
  }

  .sv_matrix_cell_header {
    font-size: var(--fs-milli);
    font-weight: var(--normal-weight) !important;
    color: var(--secondary) !important;
    text-align: left;
    margin-bottom: var(--regular-spacing);
    padding-bottom: var(--regular-spacing) !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
    max-width: 12.5rem;

    span {
      &:nth-child(3) {
        display: none;
      }
    }

    @media screen and (min-width: 700px) {
      font-size: var(--fs-milli);
    }
  }

  div[data-name="k_control_of_real_principals_list"],
  div[data-name="t_control_of_real_principals_list"] {
    .sv_matrix_cell {
      &:nth-child(4) {
        width: 17%;
      }
    }
    .sv_qcbc.sv_qcbx {
      display: flex;
    }
  }

  div[data-key="insur_pmtdate0"] {
    .sv_q_title {
      @media screen and (min-width: 700px) {
        max-width: 12.5rem;
        min-height: 2.9rem;
      }
    }
  }
}

div[data-key="t_policyholder_sign0"],
div[data-key="k_signature_of_policyholders0"] {
  .sv_matrix_cell_header:nth-last-child(2),
  .sv_matrix_cell:nth-last-child(2) {
    display: none;
  }
}

.sv_panel_dynamic {
  hr {
    display: none;
  }
}
