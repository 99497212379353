/* DataTable Styles Start */
.p-datatable-header {
  background-color: var(--white) !important;
  border-bottom: 1px solid var(--border-line) !important;
  box-shadow: var(--box-shadow);
  padding: var(--sm-spacing) !important;
}
.column-md-display {
  display: none;
}
.notifications-list-wrapper {
  .p-datatable-wrapper {
    .p-datatable-tbody {
      td {
        cursor: pointer;

        label {
          cursor: pointer;
        }
      }
    }
  }
}
th {
  color: var(--text) !important;
  font-weight: var(--bold-weight) !important;
  font-size: var(--fs-micro);
  line-height: var(--lh-h5);
  letter-spacing: var(--ls-regular);
  padding: var(--md-spacing) var(--md-spacing) var(--md-spacing)
    var(--sm-spacing) !important;

  .p-checkbox .p-checkbox-box {
    display: none;
  }
}
.datatable-doc-demo {
  .p-paginator {
    .p-paginator-current {
      margin-left: auto;
    }
  }
  .table-header {
    display: flex;
    justify-content: space-between;
  }
  .p-datepicker {
    min-width: 25rem;
    td {
      font-weight: 400;
    }
  }
  .p-datatable.p-datatable-customers {
    .p-datatable-header {
      text-align: left;
      font-size: 1.5rem;
      border: none;
    }
    .p-paginator {
      background: var(--transparent);
      padding: 1rem;
      border: none;
    }
    .p-datatable-tbody {
      > tr {
        td {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: block;
          }
          &:last-child {
            display: block;
          }
          &:nth-last-child(2) {
            display: none;
          }
        }
        > td {
          cursor: auto;
          border: none !important;
        }
      }
    }
    .p-datatable-thead {
      > tr {
        th {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: block;
          }
          &:nth-last-child(2) {
            display: none;
          }
        }
        > th {
          text-align: left;
        }
      }
    }
    .p-dropdown-label {
      &:not(.p-placeholder) {
        text-transform: uppercase;
      }
    }
  }
  .p-datatable-customers {
    .p-datatable-tbody {
      > tr {
        > td {
          .p-column-title {
            display: none;
          }
        }
      }
    }
  }
}
.p-datatable-tbody {
  tr {
    td {
      cursor: pointer;

      &.p-selection-column {
        vertical-align: middle;
      }

      &:nth-child(1) {
        background-color: var(--turquoise-light);
      }
    }
  }
}
.p-datatable {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radii-regular);
  overflow-x: auto;
  .p-datatable-tfoot {
    > tr {
      > td {
        background: var(--white);
      }
    }
  }
  .p-datatable-thead {
    > tr {
      > th {
        background: var(--white) !important;
        width: 100px;

        .p-sortable-column-icon {
          vertical-align: middle;

          &::before {
            position: relative;
            top: 2px;
          }
        }
      }
    }
  }
  .p-sortable-column.p-highlight {
    &:not(.p-sortable-disabled) {
      &:hover {
        background: var(--white);
        color: var(--text);
      }
    }
    color: var(--text);
    background: var(--white);
  }
}
.p-datatable.p-datatable-hoverable-rows {
  .p-datatable-tbody {
    > tr {
      &:not(.p-highlight) {
        &:hover {
          background: var(--table-hover);
        }
      }
    }
  }
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: var(--transparent);
  outline-color: var(--transparent) !important;
}

td {
  .p-checkbox {
    outline-color: var(--transparent);
    box-shadow: none;
    .p-checkbox-box {
      width: 15px;
      height: 15px;
      background: var(--transparent) !important;
      outline: none;
    }
    .p-checkbox-box.p-highlight {
      border: none;
      background: var(--transparent) !important;
      outline: none;
      .pi-check:before {
        content: "\f755" !important;
        color: var(--success) !important;
        font-size: var(--fs-h6);
        border: 1px solid var(--light-grey);
        border-radius: var(--border-radii-medium);
      }
    }
  }
  outline-color: var(--transparent) !important;
  .pi-check:before {
    content: "\f755" !important;
    color: var(--success) !important;
    font-size: var(--fs-h6);
    border: 1px solid var(--light-grey);
    border-radius: var(--border-radii-medium);
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--transparent);
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #ced4da;
  }
}

.pi-angle-left:before {
  content: "\fb49";
}
.pi-times::before {
  content: "\fa59";
  font-size: var(--fs-h5);
}
.pi-angle-right:before {
  content: "\fb4a";
}
.p-sortable-column-icon {
  margin-left: var(--xs-spacing) !important;
  color: var(--text) !important;
  font-size: var(--xs-spacing);
}
.p-sortable-column:focus {
  box-shadow: none !important;
}
.pi-sort-amount-up-alt:before,
.pi-sort-amount-down:before,
.pi-sort-alt:before {
  content: "\fb48" !important;
  font-size: var(--fs-mini);
}
.pi-check:before {
  content: "\f755";
}
.p-paginator-bottom {
  border: none;
  padding: var(--md-spacing);

  .p-dropdown {
    .p-dropdown-label {
      padding: var(--regular-spacing) 0 var(--regular-spacing) 0;
    }
  }
}
.p-paginator {
  justify-content: end !important;
  .p-paginator-prev {
    min-width: var(--xl-spacing);
    height: var(--lg-spacing);
    color: var(--text);
  }
  .p-paginator-next {
    min-width: var(--xl-spacing);
    height: var(--lg-spacing);
    color: var(--text);
  }
  .p-disabled {
    opacity: 0.4;
  }

  .p-paginator-pages {
    .p-paginator-page.p-highlight {
      background: var(--blue-dark);
      border-color: var(--blue-dark);
      color: var(--white);
    }
    .p-paginator-page {
      &:not(.p-highlight) {
        &:hover {
          color: var(--blue-dark);
          background: var(--turquoise-light);
        }
      }
    }
  }
}
.p-link {
  &:focus {
    box-shadow: none;
  }
}
@media screen and (max-width: 960px) {
  .p-datatable {
    width: 100%;
    table {
      width: 1100px;
    }
  }
  .datatable-doc-demo {
    .p-datatable.p-datatable-customers {
      .p-datatable-thead {
        > tr {
          > th {
            display: none !important;
          }
          th {
            &:nth-child(1) {
              display: flex;
              justify-content: space-between;
            }
            &:nth-child(2) {
              display: none;
            }
            &:last-child {
              display: none;
            }
          }
        }
      }
      .p-datatable-tfoot {
        > tr {
          > td {
            display: none !important;
          }
        }
      }
      .p-datatable-tbody {
        > tr {
          td {
            &:nth-child(1) {
              display: flex;
              justify-content: space-between;
            }
            &:nth-child(2) {
              display: none;
            }
            &:last-child {
              display: none;
            }
            &:nth-last-child(2) {
              display: flex;
            }
          }
          > td {
            display: flex;
            justify-content: space-between;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;
            .p-column-title {
              min-width: 30%;
              display: inline-block;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              text-transform: uppercase;
              color: var(--text) !important;
              font-weight: var(--medium-weight) !important;
              font-size: var(--fs-milli);
              line-height: 22px;
            }
            .p-progressbar {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
  .p-datatable.p-datatable-customers {
    .p-datatable-tbody {
      tr {
        td {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .p-paginator {
    justify-content: start !important;
    padding: var(--regular-spacing) 0;

    > div {
      margin-top: 0;
    }

    .p-dropdown {
      margin-left: 0;
    }
  }
}
/* DataTable Styles End */
