.pi-star:before {
  content: "\fde2";
  color: var(--primary);
}

.pi-star-o:before {
  content: "\fde2";
  color: var(--grey-dark);
}
.p-rating .p-rating-icon {
  margin-left: var(--xs-spacing);
}
