/* Colors */
$white: #ffffff;
$yellow: #e89e28;
$yellow-light: #fcf1df;
$blue-darkest: #3053A4;
$blue-dark: #223c77;
$blue-medium: #3053a4;
$blue-logo: #7c97d3;
$turquoise: #5da8b2;
$turquoise-light: #e9f1f3;
$grey: #5d5d5d;
$grey-light: #ebebeb;
$grey-lightest: #dee2eb;
$grey-dark: #12120d;
$red: #ebebeb;
$red-dark: #f44336;

/* Font Weight */
$hairline-weight: 100;
$thin-weight: 200;
$light-weight: 300;
$normal-weight: 400;
$medium-weight: 500;
$semibold-weight: 600;
$bold-weight: 700;
$xbold-weight: 800;
$black-weight: 900;

/* Device Resolutions */
$xs: 599px;
$lg: 600px;
$xl: 900px;
$xxl:1200px;
$xxxl: 1800px;
