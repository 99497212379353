/* Accordion Styles Start */
@import "../variables";

.p-accordion {
  font-family: inherit;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radii-regular);
  .p-accordion-header-link {
    &:focus {
      z-index: 1;
    }
  }
  .p-accordion-header-text {
    line-height: var(--lh-milli);
    color: var(--text);
    font-weight: var(--normal-weight);
    font-size: var(--fs-base);
  }
  .p-accordion-header {
    .p-accordion-header-link {
      border: none;
      background: var(--white);
      border-radius: 0;
      transition: box-shadow 0.2s;
      padding: var(--sm-spacing) var(--xl-spacing) var(--sm-spacing)
        var(--md-spacing);
    }
    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        background: var(--white);
        border-color: none;
      }
      &:hover {
        .p-accordion-header-link {
          border-color: none;
          background: var(--white);
        }
      }
    }
    &:not(.p-disabled) {
      .p-accordion-header-link {
        &:focus {
          box-shadow: none;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          .p-accordion-header-link {
            background: var(--white);
          }
        }
      }
    }
  }
  .p-accordion-content {
    border: none;
    color: var(--text);
    background-color: var(--transparent);
    padding: var(--sm-spacing) var(--md-spacing);
    font-size: var(--fs-milli);
    line-height: var(--lh-base);
  }
  .p-accordion-tab {
    position: relative;
    transition: margin-bottom 225ms;
    .p-accordion-toggle-icon {
      order: 1;
      margin-left: auto;
      transition: transform 0.2s;
      color: var(--text);
      font-size: var(--fs-h3);
      &::before {
        content: "\f731";
      }
    }
    &:not(.p-accordion-tab-active) {
      .p-accordion-header-link {
        &:focus {
          background: var(--white);
        }
      }
    }
    .p-accordion-header.p-disabled {
      opacity: 1;
      .p-accordion-header-link {
        > * {
          opacity: 0.38;
        }
      }
    }
  }
  .p-accordion-tab.p-accordion-tab-active {
    border-top: 1px solid var(--border-line);
    &:first-child {
      border-bottom: 1px solid var(--border-line);
      border-top: 0;
    }
    .p-accordion-toggle-icon {
      transform: rotate(-180deg);
    }
  }
}

.pi-chevron-right:before {
  content: "\e91f";
}
.pi-chevron-down:before {
  content: "\e91f";
}
/* Accordion Styles End */
