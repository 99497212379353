@import "./variables.scss";

/* Mixins */
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin shadowHeader() {
  @include box-shadow(0px, 0px, 40px, rgba(0, 0, 0, 0.06));
}

@mixin smallCards() {
  @include box-shadow(0px, 4px, 30px, rgba(0, 0, 0, 0.15));
}

/* Media Query */
@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: $xs) { @content; }
  } @else if $size == tablet-portrait-up {
    @media (min-width: $lg) { @content; }
  } @else if $size == tablet-landscape-up {
    @media (min-width: $xl) { @content; }
  } @else if $size == desktop-up {
    @media (min-width: $xxl) { @content; }
  } @else if $size == big-desktop-up {
    @media (min-width: $xxxl) { @content; }
  }
}

/* Border radius */
@mixin border-radius($tl:null, $tr:null, $br:null, $bl:null, $b: null, $bt: null, $bc: null) {
  border: $b $bt $bc;
  border-top-left-radius: $tl;
  border-top-right-radius: $tr;
  border-bottom-right-radius: $br;
  border-bottom-left-radius: $bl;
}

/* Heading Font Size */
$font-size:(
    h1 : 40px,
    h2 : 35px,
    h3 : 26px,
    h4 : 20px,
    h5 : 18px,
    h6 : 14px
);

@each $header, $size in $font-size {
    #{$header}{ font-size: $size; }
}