:root {
  /* Colors */
  --primary: #fbc02d;
  --primary-hover: rgb(255, 255, 255, 0.5);
  --table-hover: rgb(237, 177, 34, 0.1);
  --light-yellow: rgb(237, 177, 34, 0.2);
  --secondary: #1976d2;
  --text: #212121;
  --blue: #0b61b6;
  --blue-light: #1976d2;
  --white: #ffffff;
  --bg-white: #f9fafc;
  --border-line: #e4e4e4;
  --success: #66bb6a;
  --alert: #d32f2f;
  --grey: #e0e0e0;
  --grey-light: #eeeeee;
  --grey-dark: #9e9e9e;
  --light-green: #99e9ba;
  --light-grey: #e5e5e5;
  --transparent: transparent;
  --purple: rgb(43, 51, 147, 0);
  --violet: #673ab7;
  --grey-thin: #dee2e6;

  /* Font Weight */
  --hairline-weight: 100;
  --thin-weight: 200;
  --light-weight: 300;
  --normal-weight: 400;
  --medium-weight: 500;
  --semibold-weight: 600;
  --bold-weight: 700;
  --xbold-weight: 800;
  --black-weight: 900;

  /* base font size - applied at body / html level */
  --fs-base: 16px;

  /* larger than heading font sizes */
  --fs-tera: 144px;
  --fs-giga: 80px;
  --fs-mega: 70px;
  --fs-kilo: 60px;

  /* heading font sizes */
  --fs-h1: 32px;
  --fs-h2: 28px;
  --fs-h3: 24px;
  --fs-h4: 20px;
  --fs-h5: 18px;
  --fs-h6: 16px;

  /* smaller than heading font sizes */
  --fs-max: 42px;
  --fs-milli: 14px;
  --fs-micro: 12px;
  --fs-mini: 10px;
  --fs-nano: 8px;
  --fs-pico: 6px;

  /* icons font size */

  --icon-fs-max: 15px;
  --icon-fs-milli: 11px;
  --icon-fs-micro: 9px;
  --icon-fs-nano: 6px;

  /* heading line heights */
  --lh-h1: 38px;
  --lh-h2: 32px;
  --lh-h3: 28px;
  --lh-h4: 24px;
  --lh-h5: 22px;
  --lh-h6: 26px;

  /* base line-height*/
  --lh-small: 14px;
  --lh-base: 20px;
  --lh-medium: 26px;

  /* body line heights */
  --lh-micro: 14px;
  --lh-milli: 26px;

  /* box shadow */
  --box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);

  /* border-radius */
  --border-radii-small: 2px;
  --border-radii-medium: 6px;
  --border-radii-regular: 8px;
  --border-radii-large: 50px;

  /* spacing */
  --xs-spacing: 4px;
  --xs-small-spacing: 6px;
  --regular-spacing: 8px;
  --sm-spacing: 10px;
  --md-spacing: 16px;
  --lg-spacing: 20px;
  --xl-spacing: 24px;
  --xxl-spacing: 30px;
  --xxxl-spacing: 50px;

  /* letter spacing*/
  --ls-regular: 0.07px;
}
