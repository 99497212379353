@import "../sass/partials/reset.scss";
@import "../sass/partials/variables.scss";
@import "../sass/partials/mixins.scss";
@import "../sass/partials/icon.scss";

@import "../sass/components/datatable.scss";
@import "../sass/components/calendar.scss";
@import "../sass/components/accordion.scss";
@import "../sass/components/sidebar.scss";
@import "../sass/components/dropdown.scss";
@import "../sass/components/rating.scss";
@import "../sass/components/steps.scss";
@import "../sass/components/loader.scss";
@import "../sass/components/surveyjs.scss";
@import "../sass/components/surveyRating.scss";
@import "../sass/components/surveyMatrix.scss";
@import "../sass/components/surveyFileUpload.scss";

@import "./variables.css";
