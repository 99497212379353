@mixin breakpoint {
  @media (min-width: 700px) {
    @content;
  }
}

.progressbar-steps {
  display: flex;
  padding: 0;
  list-style: none;

  @include breakpoint {
    margin-left: 0;
  }

  li {
    display: flex;
    flex: 1;
    position: relative;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
    }

    // Creates background bar
    &:before {
      background-color: var(--white);
      margin-top: var(--xs-spacing);
      left: 2px;
      height: 1px;
      width: 100%;

      @include breakpoint {
        margin-top: var(--xs-spacing);
        height: 1px;
      }
    }

    // Creates node circle
    &:after {
      background-color: var(--white);
      border-radius: 50%;
      width: 10px;
      height: 10px;

      @include breakpoint {
        width: 10px;
        height: 10px;
      }
    }

    // Remove width for last step in mobile
    &:last-child {
      div {
        min-width: auto;
      }
      flex: 0;
      @include breakpoint {
        flex: 1;
      }
    }

    // Remove bar for last step
    &:last-child:before {
      display: none;
    }
    // Background color for completed and active steps
    &.completed:before,
    &.completed:after,
    &.active:after {
      background-color: var(--secondary);
      border-color: var(--secondary);
    }

    // Border size remains thin for completed steps
    &.completed:after {
      border-width: 2px;
    }
  }
}
