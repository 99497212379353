/* Sidebar styles */
@media screen and (min-width: 960px) {
  .p-component-overlay {
    &.p-sidebar-mask {
      display: none !important;
    }
  }
}

.p-sidebar {
  .topMenu {
    max-height: 100%;
    overflow: auto;
    margin-bottom: 1.5rem;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: var(--bg-white);
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: var(--bg-white);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--text);
    }
  }
}
