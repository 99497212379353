.p-dropdown-panel {
  background: var(--bg-white);
  box-shadow: unset;
  border: 1px solid var(--grey);
  border-radius: var(--border-radii-regular);
  margin-top: var(--xs-spacing);
  .p-dropdown-items {
    padding: var(--xs-spacing) 0;
    .p-dropdown-item {
      color: var(--text);
      font-size: var(--fs-milli);
      line-height: var(--lh-base);
      padding: var(--xs-spacing) var(--sm-spacing);
      &:not(.p-highlight):not(.p-disabled):hover {
        background: var(--white);
        color: var(--text);
      }
      &.p-highlight {
        background: unset;
        color: var(--text);
      }
    }
  }
}
