.sv_main {

  .sv_q_rating {
    margin-bottom: var(--xl-spacing);
    margin-top: var(--lh-h5);

    .sv_q_rating_item {
      position: relative;
      background: var(--white);
      box-shadow: var(--box-shadow);
      border-radius: var(--border-radii-regular);
      white-space: nowrap;
      padding: calc(1.25 * var(--base-unit, 8px));
      box-sizing: border-box;
      min-width: calc(7 * var(--base-unit, 8px));
      text-align: center;
      border: 2px solid var(--white);
      color: var(--text);
      margin-right: var(--sm-spacing);
      cursor: pointer;

      &:hover {
        border-color: var(--blue);
      }

      &.active {
        background-color: var(--blue);
        border-color: var(--blue);
        color: var(--white);
      }

      .sv_q_rating_item_text {
        line-height: calc(3 * var(--base-unit, 8px));
        min-width: calc(3 * var(--base-unit, 8px));
        display: inline-block;
      }
    }
  }
}
