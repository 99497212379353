.sv_main {
  width: 100%;
  margin: 0.5rem 0 var(--md-spacing);

  .sv_qstn {
    min-width: 100% !important;
    max-width: 100%;
    margin: var(--sm-spacing) 0;

    @media screen and (max-width: 700px) {
      padding: 0 !important;
      margin: var(--sm-spacing) 0;
    }
  }

  .sv_header__text {
    h3 {
      font-size: var(--fs-h1);
      font-weight: var(--bold-weight);
      color: var(--primary);
      margin-bottom: var(--fs-h1);
    }
  }

  .sv_page_title,
  div#sq_551i {
    display: none;
  }

  .sv_p_container,
  div[data-key="occupational_pension_transferform_page0"],
  div[data-key="prod_purpose0"],
  div[data-key="customer_assessed_risk_level0"],
  div[data-key="fund_selection_in_insurance0"],
  div[data-key="advisor_dissuade_sol0"],
  div[data-key="counseling_date0"],
  #sp_329 {
    border-radius: var(--border-radii-regular);
    box-sizing: border-box;
    background: var(--white);
    box-shadow: var(--box-shadow);
    margin: var(--regular-spacing) 0;
    padding: var(--xxl-spacing);

    .sv_p_title {
      padding: 0;
      margin-bottom: var(--regular-spacing);
      font-size: var(--fs-h3);
      font-weight: var(--semibold-weight);

      @media screen and (max-width: 700px) {
        font-size: var(--fs-base);
      }
    }
  }

  .sv_panel_dynamic,
  .sv_p_container {
    .sv_p_container {
      padding: 0;
      margin: 0;
      box-shadow: unset;

      h4 {
        font-size: var(--fs-base);
        margin-top: var(--sm-spacing);
        margin-bottom: var(--regular-spacing);
        font-weight: var(--semibold-weight);

        @media screen and (max-width: 700px) {
          font-size: var(--fs-milli);
        }
      }
    }
  }

  div[data-key="tpk_fund_distribution0"] {
    .sv_row {
      align-items: center;
    }
  }

  .sv_q_title {
    color: var(--secondary);
    font-weight: var(--bold-weight);
    margin: 0 0 var(--regular-spacing);
    margin-bottom: var(--regular-spacing);
    font-size: var(--fs-micro);

    @media screen and (min-width: 700px) {
      font-size: var(--fs-milli);
    }
  }

  .sv_q_erbox {
    color: var(--alert);
    font-size: var(--fs-micro);
    max-width: fit-content;

    > div {
      &:nth-child(2) {
        display: none;
      }
    }
  }

  .sv_p_description,
  .sv_q_description {
    font-size: var(--fs-micro);
    margin-bottom: var(--regular-spacing);

    @media screen and (min-width: 700px) {
      font-size: var(--fs-milli);
    }
  }

  input[type="checkbox"] {
    margin-right: var(--sm-spacing);
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        width: 1.25rem;
      }
    }
  }

  input[type="text"],
  input[type="number"],
  input[type="date"] {
    width: 100% !important;
    max-width: 12.5rem !important;
    background: var(--bg-white);
    border: 1px solid var(--grey);
    border-radius: var(--border-radii-medium);
    padding: var(--fs-micro) var(--sm-spacing);
    font-size: var(--fs-milli) !important;
    margin-bottom: var(--regular-spacing);

    @media screen and (max-width: 700px) {
      max-width: unset !important;
    }

    &:focus {
      border-color: var(--secondary);
    }

    &:focus-visible {
      outline: unset;
    }

    &#sq_124i {
      max-width: unset !important;
    }
  }

  input[type="date"] {
    width: unset !important;
  }

  div[data-key="t_are_there_real_principals0"],
  div[data-key="control_of_real_principals0"] {
    .sv-q-column-2,
    .sv-q-column-3 {
      max-width: 100%;
    }
  }

  div[data-key="advisor_select0"] {
    select {
      max-width: 12.5rem;
    }
  }

  select,
  textarea {
    width: 100%;
    background: var(--bg-white);
    border: 1px solid var(--grey);
    border-radius: var(--border-radii-medium);
    padding: var(--fs-micro) var(--sm-spacing);
    font-size: var(--fs-milli);

    &:focus {
      border-color: var(--secondary);
    }

    &:focus-visible {
      outline: unset;
    }
  }

  .sv_select_wrapper {
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        margin: 0.625rem 0;
      }
    }

    select {
      height: 2.6875rem;
      font-size: 0.8125rem;
    }
  }

  .sv_q_radiogroup_label {
    display: flex;
    line-height: 1.5;
    align-items: flex-start;
  }

  .sv_q_checkbox_control_label,
  .sv_q_radiogroup .sv-string-viewer {
    font-size: var(--fs-milli);
    width: calc(100% - 13px);
    line-height: 1.2;
  }

  .sv_q_radiogroup {
    .sv-string-viewer {
      font-size: var(--fs-milli);
      line-height: 1.2;
    }
  }

  .sv_qcbc.sv_qcbx {
    min-height: var(--lh-base);
    margin: 0 0 var(--regular-spacing);
  }

  .sv_q_select_column {
    @media screen and (max-width: 700px) {
      max-width: 100% !important;
      margin-bottom: var(--sm-spacing);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sv_q_checkbox_label {
    display: flex;
    align-items: center;
  }

  .sv_q_checkbox {
    margin-bottom: var(--md-spacing);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .sv_q {
    overflow-y: hidden;
  }

  div[data-key="simple_desc0"] {
    .sv_q {
      margin: -10px 0 0;

      .sv_qcbx {
        display: none;
      }
    }
  }

  .sv_q_radiogroup_control_item {
    margin-right: 10px;
    margin-top: 6px;
  }

  button,
  input[type="button"] {
    border: 0;
    padding: var(--sm-spacing) var(--xxxl-spacing);
    font-size: var(--fs-base);
    font-weight: var(--semibold-weight);
    border-radius: var(--border-radii-medium);
    cursor: pointer;

    @media screen and (max-width: 700px) {
      font-size: var(--fs-milli);
      padding: var(--sm-spacing) var(--lg-spacing);
    }
  }

  .sv-action-bar {
    padding-left: 0 !important;
    margin-top: var(--md-spacing);

    .sv_next_btn,
    .sv_preview_btn,
    .sv_complete_btn {
      background: var(--secondary);
      color: var(--white);

      &:hover {
        background: var(--blue);
      }
    }

    .sv_prev_btn {
      background: var(--grey-light);
      color: var(--secondary);
      margin-right: var(--xl-spacing);

      &:hover {
        color: var(--blue);
      }
    }

    #sv-nav-complete {
      .sv-action__content {
        flex-direction: row-reverse;
      }
    }
  }

  .reset-btn {
    margin-top: 1rem;
  }

  div[data-key="cross_questions0"] {
    .sv_row > div {
      width: fit-content !important;
      flex: unset !important;
    }

    div[data-key="chk_notchosenfund0"] {
      width: 100% !important;
      border-bottom: 1px solid var(--grey-thin);
    }
  }

  div[data-key="accommodation0"] {
    div[data-key] {
      &:first-child {
        min-width: 14% !important;
        width: 14% !important;
        flex: unset !important;
        flex-basis: unset !important;

        @media screen and (max-width: 700px) {
          min-width: 100% !important;
          width: 100% !important;
        }
      }
    }

    div[data-key="condominium_market_value-1"],
    div[data-key="condominium_levarage-2"],
    div[data-key="condominium_share-3"],
    div[data-key="condominium_maintenance-4"] {
      @media screen and (min-width: 700px) {
        min-width: unset !important;
      }
    }
  }

  div[data-key="private_savings0"] {
    div[data-key]:not([data-key="savings_insur_totalamtswe0"]) {
      &:first-child {
        min-width: 30% !important;
        flex: unset !important;
        flex-basis: unset !important;

        @media screen and (max-width: 700px) {
          min-width: 100% !important;
          width: 100% !important;
        }
      }
    }
  }

  div[data-key="insurance_distribution0"],
  div[data-key="panel1-1"] {
    box-shadow: var(--box-shadow);
    border-radius: var(--regular-spacing);
    background: var(--white);
    width: 48% !important;
    flex: 1 1 48% !important;

    .sv_p_container {
      box-shadow: unset;
    }
  }

  div[data-key="panel1-1"] {
    @media screen and (min-width: 700px) {
      margin-left: var(--sm-spacing);
    }
  }

  div[data-key="insurances0"],
  div[data-key="insurance_details0"],
  div[data-key="t_chairman_appointment0"],
  div[data-key="p_other_distribution_details0"] {
    .sv_q_title {
      margin-left: 0;
      color: var(--text);
      font-size: var(--fs-base);
      margin-bottom: var(--fs-micro);
      font-weight: var(--semibold-weight);

      @media screen and (max-width: 700px) {
        font-size: var(--fs-milli);
      }
    }
  }

  div[data-key="ordered_files0"],
  div[data-key="file_type-1"] {
    .sv_q_title,
    .sv_p_title {
      font-size: var(--fs-milli);
      color: var(--secondary);
      font-weight: var(--bold-weight);
      margin: 0 0 var(--regular-spacing);
      margin-bottom: var(--lg-spacing);
    }

    .sv-ranking-item {
      margin-bottom: var(--lg-spacing);
    }

    .sv_panel_dynamic {
      .sv_p_wrapper {
        &:last-child {
          div[data-key="select_type0"] {
            & > div {
              margin-bottom: 0;
            }
          }
        }

        div[data-key="select_type0"] {
          & > div {
            margin-top: 0.5rem;
            margin-bottom: 32px;

            fieldset {
              display: flex;

              & > div {
                max-width: max-content;
              }
            }
          }
        }
      }
    }
  }

  div[data-key="fund_selection_in_insurance0"] {
    .sv_q_title {
      margin-left: 0;
      color: var(--text);
      font-size: var(--fs-h3);
      font-weight: var(--semibold-weight);
      margin-bottom: var(--fs-micro);

      @media screen and (max-width: 700px) {
        font-size: var(--fs-base);
      }
    }
  }

  div[data-key="insurances0"],
  div[data-key="customer_full_name_ssn0"],
  div[data-key="customer_phonenumber_email0"],
  div[data-key="advisor_phonenumber_email0"],
  div[data-key="advisor_name0"],
  div[data-key="insurance_details0"] {
    .sv_matrix_cell_header {
      display: none;
    }

    input[type="text"] {
      background: unset;
      padding: 0;
      border: unset;
      color: var(--text);
      font-size: var(--fs-base);
      -webkit-text-fill-color: var(--text);
      opacity: 1; /* required on iOS */

      &:disabled {
        color: var(--text);
        -webkit-text-fill-color: var(--text);
        opacity: 1; /* required on iOS */
        max-width: unset !important;
      }
    }

    .sv_q_matrix_dynamic {
      width: 100%;
    }
  }

  .sv_q_footer {
    margin-bottom: var(--md-spacing);
  }

  .sv_q_required_text {
    display: none;
  }

  #cancel-preview {
    .sv_edit_btn {
      display: none;
    }
  }

  div[data-key="customer_details0"],
  div[data-key="accommodation0"],
  div[data-key="debt_other0"],
  div[data-key="private_savings0"],
  div[data-key="payment0"],
  div[data-key="tpk_fund_distribution0"],
  div[data-key="yes_insured_person_other_than_the_policyholder0"],
  div[data-key="yes_premium_payers_other_than_insured0"],
  div[data-key="premium0"],
  div[data-key="direct_debit0"],
  div[data-key="fees_and_payment0"],
  div[data-key="signature_of_insured0"],
  div[data-key="signature_of_premium_payer0"],
  div[data-key="company_contact_person0"] {
    div[data-key]:not([data-key="customer_phonenumber_email0"]) {
      width: unset !important;
      flex: unset !important;
      min-width: unset !important;

      @media screen and (max-width: 700px) {
        min-width: 100% !important;
      }

      > div {
        min-width: 14rem !important;
      }
    }
  }

  div[data-key="insur_pmtdate0"],
  div[data-key="owner_name0"],
  div[data-key="insur_pmtperiod-1"],
  div[data-key="chk_insurper_50"] {
    width: unset !important;
    flex: unset !important;
    min-width: unset !important;

    @media screen and (max-width: 700px) {
      min-width: 100% !important;
    }

    > div {
      min-width: 12.5rem !important;
    }
  }

  div[data-key="rehouse_share-3"],
  div[data-key="apartment_rent-2"],
  div[data-key="isk_market_value-1"],
  div[data-key="savings_sav_stock_mvalue-1"],
  div[data-key="savings_fund_mvalue-1"],
  div[data-key="savings_sav_struct_mvalue-1"],
  div[data-key="savings_banksavings_bank-2"],
  div[data-key="k_insured_email-1"],
  div[data-key="question3-1"],
  div[data-key="other_share-3"],
  div[data-key="k_one_time_premium-1"],
  div[data-key="company_contact_person_address-1"],
  div[data-key="company_contact_person_pincode-1"] {
    > div {
      padding-right: 1.25rem;
    }
  }

  div[data-key="insurance_distribution0"],
  div[data-key="panel1-1"],
  div[data-key="direct_debit0"],
  div[data-key="t_chk_policyholder_companydetails0"],
  div[data-key="k_chk_policyholder_companydetails0"] {
    .sv_q_checkbox_label {
      align-items: start;
    }
  }

  div[data-key="select_type0"] fieldset > div {
    max-width: 100% !important;
    width: 100% !important;
  }

  input[type="checkbox"]:disabled:checked {
    filter: invert(100%) hue-rotate(18deg) brightness(3);
  }

  input[type="radio"]:disabled:checked {
    filter: invert(100%) hue-rotate(18deg) brightness(3);
  }

  input[type="checkbox"],
  input[type="radio"] {
    width: 13px;
    height: 13px;
  }

  div[data-key="t_control_of_policyholder0"],
  div[data-key="p_control_of_policyholder0"],
  div[data-key="k_control_of_policyholder0"] {
    .sv_row {
      &:not(:nth-child(1)) {
        border-top: 1px solid var(--grey-thin);
      }
      &:last-child {
        border-bottom: 1px solid var(--grey-thin);
      }
    }
  }
  div[data-key="t_chk_autogiro_approved_types0"],
  div[data-key="p_chk_autogiro_approved_types0"],
  div[data-key="k_chk_autogiro_approved_types0"] {
    .sv_q_select_column.sv-q-column-2 {
      width: 100%;
      padding-right: 0;
    }
  }
}
