/**
 * ==============================================
 * Dot Pulse Loader
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: var(--border-radii-regular);
  background-color: var(--primary);
  color: var(--primary);
  box-shadow: 9999px 0 0 -5px var(--primary);
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: var(--border-radii-regular);
  background-color: var(--primary);
  color: var(--primary);
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px var(--primary);
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px var(--primary);
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px var(--primary);
  }
  30% {
    box-shadow: 9984px 0 0 2px var(--primary);
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px var(--primary);
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px var(--primary);
  }
  30% {
    box-shadow: 9999px 0 0 2px var(--primary);
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px var(--primary);
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px var(--primary);
  }
  30% {
    box-shadow: 10014px 0 0 2px var(--primary);
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px var(--primary);
  }
}
